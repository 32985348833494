import React, { useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import CheckoutForm from '../components/CheckoutForm'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import anna from '../images/anna2.png'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

const DescriptionGuide = () => {
  const [clientSecret, setClientSecret] = useState('')
  let [isOpen, setIsOpen] = useState(false)
  let [title, setTitle] = useState('')
  let [text, setText] = useState('')
  let [price, setPrice] = useState('')

  async function createPaymentIntent(item) {
    setIsOpen(true)
    const res = await fetch('/.netlify/functions/create-payment-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ item: { id: item } }),
    })
    const data = await res.json()
    setClientSecret(data.clientSecret)
  }

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#805ad5',
      colorBackground: '#fff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '4px',
      borderRadius: '4px',
    },
    rules: {
      '.Tab': {
        border: '1px solid #E0E6EB',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
      },

      '.Tab:hover': {
        color: 'var(--colorText)',
      },

      '.Tab--selected': {
        borderColor: '#E0E6EB',
        boxShadow:
          '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
      },

      '.Input--invalid': {
        boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
      },

      '.Label': {
        color: '#2d3748',
        fontWeight: 'bold',
      },

      '.Dropdown': {
        borderRadius: '4px',
      },

      '.Block': {
        colorBackground: '#fff',
      },

      // See all supported class names and selector syntax below
    },
  }
  const options = {
    clientSecret,
    appearance,
  }

  return (
    <Layout>
      <SEO title="Order fulfilled" />
      <section>
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm isOpen={isOpen} setIsOpen={setIsOpen} title={title} text={text} price={price} />
          </Elements>
        )}
      </section>
      <section className="p-8 mt-6 mx-auto font-header flex flex-col items-center bg-custom-cream md:max-w-6xl md:rounded-lg">
        <h2 className="text-2xl text-center my-4">
          <strong>Chers parents,</strong>
        </h2>
        <p className="text-xl p-4">
          <i>
            <strong>En tant que maman et spécialiste en sommeil des bébés</strong>
          </i>
          , je sais parfaitement que les quatre premiers mois de la vie de bébé sont une période souvent très
          chargée : en émotions positives, vous ressentez du bonheur, de la joie et de la tendresse en voyant
          le petit poupon dans vos bras, mais aussi en émotions négatives, vous êtes angoissés, vous avez des
          doutes, des questions, parfois vous vous sentez désespérés. Le sommeil de votre nouveau-né est
          souvent une source de préoccupation, malgré l&apos;idée reçue de “dormir comme un bébé”.
        </p>
        <p className="text-xl pt-4 px-4">
          <i>
            <strong>En tant que jeunes ou futurs parents</strong>
          </i>
          , vous avez peut-être mille questions y compris celles qui concernent le sommeil :
        </p>
        <ul className="text-xl pt-2 pb-4 px-4">
          <li>- Comment l&apos;organiser d&apos;une meilleure façon</li>
          <li>- Comment trouver un rythme adapté à l&apos;âge de votre bébé</li>
          <li>- Comment faire les premiers pas vers l&apos;autonomie de son sommeil</li>
          <li>
            - Comment éviter les pièges qui empêchent la progression naturelle de sommeil de votre bébé…
          </li>
        </ul>
        <p className="text-xl p-4">
          <i>
            <strong>Je suis sûre que </strong>
          </i>{' '}
          c&apos;est beaucoup plus facile de traverser cette période éprouvante en étant armé de connaissances
          spécifiques et pointues qui permettent de mieux comprendre le sommeil de votre nourrisson dans ses
          premiers mois, et de préparer de bonnes bases pour un sommeil de qualité. Plus tôt vous commencez à
          vous occuper du sommeil de votre bébé (selon les stratégies préconisées pour chaque tranche
          d&apos;âge), le mieux vous vous préparez à une parentalité heureuse et votre bébé à une croissance
          physique et mentale épanouissante.
        </p>
        <p className="text-xl p-4">
          <i>
            <strong>J&apos;ai préparé pour vous ce guide</strong>
          </i>{' '}
          qui est basé sur mon expérience d&apos;accompagnement des parents de nouveau-nés depuis 2019.
          J&apos;ai essayé de le rendre le plus pratique possible, loin de la théorie pure et dure, en y
          incluant des stratégies et des conseils correspondant à chaque tranche d&apos;âge (de 0 à 6
          semaines, de 6 à 8 semaines, de 8 à 12 semaines, et de 12 à 16 semaines). Vous allez y trouver des
          réponses à des questions qui peuvent apparaitre pendant cette période unique, des instructions
          détaillées pour la résolution des nombreux défis de cet âge, ainsi que des graphiques pour une
          meilleure visualisation.
        </p>
        <p className="text-xl p-4">
          <i>
            <strong>J&apos;espère que ce manuel</strong>
          </i>
          , qui est une véritable petite encyclopédie du sommeil pour les nouveau-nés, vous aidera pendant les
          premiers mois de votre bébé ! Je vous souhaite bon courage et beaucoup de confiance en vous-même,
          vous êtes les meilleurs parents pour votre bébé !
        </p>
        <section className="flex w-full flex-col-reverse md:flex-row items-center italic p-4 text-2xl">
          <img src={anna} alt="photo d'Anna" className="" />
          <div className="text-center mb-12 md:ml-12 md:text-left">
            <p>Cordialement,</p>
            <p>Anna</p>
            <p>Fondatrice du « Bébé au pays du sommeil »</p>
          </div>
        </section>
        <button
          className="w-7/12 p-2 mx-auto m-4 font-bold text-white rounded-full cursor-pointer bg-ugly-lilas hover:bg-ugly-lilas-hovered font-muli"
          onClick={() => {
            createPaymentIntent(process.env.GATSBY_GUIDE_COMPLET_ID)
            setTitle('Guide complet pour les futurs et jeunes parents')
            setText(`Le sommeil des Nouveau-Nés : Stratégies efficaces pour que votre bébé dorme mieux dès ses
                  premiers jours.`)
            setPrice('10,00 €')
          }}
        >
          Acheter le guide
        </button>
      </section>
    </Layout>
  )
}

export default DescriptionGuide
